// App.js
import React, { useState, useEffect, useRef } from 'react';
import { Analytics } from '@vercel/analytics/react';
import CreditPurchaseModal from './CreditPurchaseModal';
import ReactMarkdown from 'react-markdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';

import { initializeApp } from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut
} from 'firebase/auth';

import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  onSnapshot
} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDYN40xFtUfQUWV3MSg3N6BubppdThmAUw",
  authDomain: "bluey-ecb71.firebaseapp.com",
  projectId: "bluey-ecb71",
  storageBucket: "bluey-ecb71.firebasestorage.app",
  messagingSenderId: "722046019918",
  appId: "1:722046019918:web:7b6037414e437caaded61f",
  measurementId: "G-DR6ZQW9HDH"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);

function App() {
  const [inputValue, setInputValue] = useState('');
  const [conversation, setConversation] = useState([]);
  // [
  //   { role: 'system', content: 'You are a Bluebook citation AI assistant. Help the user generate a citation based on the information they provide.' },
  // ]
  const [chatStarted, setChatStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [user, setUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [pendingStart, setPendingStart] = useState(false);
  const [credits, setCredits] = useState(null);

  const [showModal, setShowModal] = useState(false);

  // Refs for inputs, chat container, and file upload.
  const initialInputRef = useRef(null);
  const chatInputRef = useRef(null);
  const chatContainerRef = useRef(null);
  const fileInputRef = useRef(null);

  // Example questions for the marquee slider.
  const exampleQuestions = [
    "How do I cite a USPTO office action?",
    "Should 'see eg' be italicized in a citation?",
    "Show an example citation for McCulloch v. Maryland.",
    "Which signal for two direct and one indirect citation?",
    "What’s the proper order for state, federal, and Supreme Court citations?",
    "How do I cite Public Law 94-14?",
    "How do I cite a law review article on constitutional law?",
    "How do I cite only a dissenting opinion?",
    "What are the rules for citing international treaties?",
    "How do I cite design vs. utility patents?",
    "How do I cite multiple sources in one citation?",
    "How do I cite oral arguments and briefs?"
  ];  

  // Set up authentication and real‑time credits.
  useEffect(() => {
    let unsubscribeSnapshot = null;
    const handleAuthStateChanged = (currentUser) => {
      setUser(currentUser);
      setAuthLoading(false);
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid);
        getDoc(userDocRef).then((userDocSnap) => {
          if (!userDocSnap.exists()) {
            // Initialize with 100 credits if the user document doesn’t exist.
            return setDoc(userDocRef, { credits: 100 });
          }
          return null;
        }).then(() => {
          unsubscribeSnapshot = onSnapshot(userDocRef, (snapshot) => {
            if (snapshot.exists()) {
              setCredits(snapshot.data().credits);
            }
          });
        }).catch(error => {
          console.error("Error initializing user credits:", error);
        });
      } else {
        setCredits(null);
        if (unsubscribeSnapshot) {
          unsubscribeSnapshot();
          unsubscribeSnapshot = null;
        }
      }
    };
    const unsubscribeAuth = onAuthStateChanged(auth, handleAuthStateChanged);
    return () => {
      unsubscribeAuth();
      if (unsubscribeSnapshot) unsubscribeSnapshot();
    };
  }, [auth, db]);

  // When pendingStart is true, wait for credits to be loaded then call handleSend.
  useEffect(() => {
    if (pendingStart && user && credits !== null) {
      if (credits > 0) {
        setPendingStart(false);
        handleSend();
      } else {
        alert('You have no credits left. Please purchase more credits to continue.');
        setPendingStart(false);
      }
    }
  }, [pendingStart, user, credits]);

  // Handler for sending a chat message via the /api/thinking endpoint.
  const handleSend = async () => {
    if (!user) {
      if (!chatStarted && inputValue.trim()) {
        setPendingStart(true);
        handleSignIn();
      }
      return;
    }
    if (!inputValue.trim()) return;
    if (credits === null) {
      alert('Loading your credits. Please wait a moment and try again.');
      return;
    }
    if (credits <= 0) {
      alert('You have no credits left. Please purchase more credits to continue.');
      return;
    }
    try {
      // Deduct one credit.
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);
      const userData = userDocSnap.data();
      if (!userData || userData.credits <= 0) {
        alert('You have no credits left. Please purchase more credits to continue.');
        return;
      }
      await setDoc(userDocRef, { credits: userData.credits - 1 }, { merge: true });

      const userMessage = { role: 'user', content: inputValue.trim() };
      const updatedConversation = [...conversation, userMessage];
      setConversation(updatedConversation);
      setInputValue('');
      if (!chatStarted) setChatStarted(true);
      setIsLoading(true);

      // Filter out system messages from history.
      const priorMessages = conversation.filter((msg) => msg.role !== 'system');
      const body = {
        user_message: userMessage.content,
        messages: priorMessages,
      };

      // Call the thinking endpoint.
      const response = await fetch('/api/thinking', { 
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Network response was not ok');
      }
      
      // Parse the JSON response and unpack the "response" field.
      const data = await response.json();
      const assistantContent = data.response;
      setConversation([...updatedConversation, { role: 'assistant', content: assistantContent }]);
    } catch (error) {
      console.error('Error fetching AI response:', error);
      setConversation(prev => [...prev, { role: 'assistant', content: 'Sorry, something went wrong. Please try again later.' }]);
    } finally {
      setIsLoading(false);
    }
  };

  // Clear the conversation.
  const handleClear = () => {
    setConversation([
      { role: 'system', content: 'You are a Bluebook citation AI assistant. Help the user generate a citation based on the information they provide.' },
    ]);
    setInputValue('');
    setChatStarted(false);
  };

  // Focus management for inputs.
  useEffect(() => {
    if (chatStarted && chatInputRef.current) {
      chatInputRef.current.focus();
    } else if (!chatStarted && initialInputRef.current) {
      initialInputRef.current.focus();
    }
  }, [chatStarted]);

  // Auto-scroll the chat container.
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [conversation, isLoading]);

  const handleSignOut = () => {
    signOut(auth).catch(err => console.error(err));
  };

  const handleSignIn = () => {
    signInWithPopup(auth, provider).catch(err => console.error(err));
  };

  const handleOpenBuyModal = () => {
    if (!user) {
      alert('Please sign in first!');
      return;
    }
    setShowModal(true);
  };

  const handleSelectPlan = async (plan) => {
    try {
      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId: user.uid, credits: plan }),
      });
      const data = await response.json();
      if (data.url) {
        window.location.href = data.url;
      } else {
        alert('Failed to create checkout session.');
      }
    } catch (err) {
      console.error(err);
      alert('Error creating checkout session.');
    }
  };

  // --- Check Brief Citations handler ---
  // Automatically triggers sign‑in if the user isn’t signed in.
  const handleCheckBriefCitations = () => {
    if (!user) {
      handleSignIn();
      return;
    }
    if (credits === null) {
      alert("Loading your credits. Please wait a moment and try again.");
      return;
    }
    if (credits <= 0) {
      alert("You have no credits left. Please purchase more credits to continue.");
      return;
    }
    fileInputRef.current.click();
  };

  // Handle file selection and call the /api/brief endpoint.
  const handleBriefUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    // Reset file input so the same file can be uploaded again.
    e.target.value = null;
    try {
      // Deduct one credit.
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);
      const userData = userDocSnap.data();
      if (!userData || userData.credits <= 0) {
        alert("You have no credits left. Please purchase more credits to continue.");
        return;
      }
      await setDoc(userDocRef, { credits: userData.credits - 1 }, { merge: true });
  
      // Ensure the chat is visible and use the same spinner as normal.
      if (!chatStarted) setChatStarted(true);
      setIsLoading(true);
  
      const formData = new FormData();
      formData.append("docxFile", file);
      
      const response = await fetch('/api/brief', {
        method: 'POST',
        body: formData,
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || "Network response was not ok");
      }
      
      const data = await response.json();
      // Append the assistant's response as a new message.
      setConversation(prev => [
        ...prev,
        { role: 'assistant', content: data.response }
      ]);
    } catch (error) {
      console.error("Error processing brief:", error);
      setConversation(prev => [
        ...prev,
        { role: 'assistant', content: "Sorry, something went wrong processing your brief." }
      ]);
    } finally {
      setIsLoading(false);
    }
  };  

  if (authLoading) {
    return (
      <div className="container-fluid d-flex flex-column justify-content-center align-items-center min-vh-100">
        <h2>Loading authentication...</h2>
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid d-flex flex-column justify-content-center align-items-center min-vh-100" style={{ padding: '1rem' }}>
        {/* Fixed Header with Sign In/Out and Credits */}
        <div className="w-100 d-flex justify-content-between mb-2" style={{ position: 'fixed', top: '1rem', zIndex: 1000 }}>
          <div style={{ marginLeft: '1rem', fontWeight: 'bold' }}>
            {user && credits !== null && `Credits: ${credits}`}
          </div>
          <div>
            {user ? (
              <>
                <button className="btn btn-sm btn-outline-success mr-2" style={{ marginRight: '5px' }} onClick={handleOpenBuyModal}>
                  Buy Credits
                </button>
                <button className="btn btn-sm btn-outline-secondary" style={{ marginRight: '5px' }} onClick={handleSignOut}>
                  Sign Out ({user.displayName || user.email})
                </button>
              </>
            ) : (
              <button className="btn btn-sm btn-outline-primary" style={{ marginRight: '5px' }} onClick={handleSignIn}>
                Sign In
              </button>
            )}
          </div>
        </div>

        {/* Main Header */}
        <div className="mt-5 text-center w-100">
          <h1 className="text-center mb-4" style={{ fontWeight: '300', fontSize: '2rem' }}>
            Legal Citation Generator
            <div style={{ fontSize: '1rem', color: '#6c757d', marginTop: '0.5rem' }}>
              AI model trained on legal citation rules
            </div>
          </h1>
        </div>

        {/* Marquee Slider for Example Questions - only visible when chat has not started */}
        {!chatStarted && (
          <div className="marquee-wrapper w-100" style={{ marginBottom: '1rem' }}>
            <div className="marquee-content">
              {[...exampleQuestions, ...exampleQuestions].map((question, idx) => (
                <div key={idx} onClick={() => setInputValue(question)} style={{ cursor: 'pointer' }}>
                  {question}
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Chat / Start Card */}
        {chatStarted ? (
          <div className="card shadow-sm border-0 w-100">
            <div className="card-body p-4 d-flex flex-column" style={{ height: '70vh' }}>
              <div
                className="mb-3 overflow-auto w-100"
                style={{ flex: '1 1 auto', border: '1px solid #dee2e6', borderRadius: '5px', padding: '1rem', backgroundColor: '#f8f9fa' }}
                ref={chatContainerRef}
              >
                {conversation.filter((msg) => msg.role !== 'system').map((msg, index) => (
                  <div
                    key={index}
                    className={`mb-3 d-flex ${msg.role === 'assistant' ? 'justify-content-start' : 'justify-content-end'}`}
                  >
                    <div>
                      <div style={{ fontWeight: '500', marginBottom: '0.25rem' }}>
                        {msg.role === 'assistant' ? 'Bluey Bot' : 'You'}
                      </div>
                      <div
                        className={`message-bubble ${msg.role === 'assistant' ? 'bg-secondary text-white' : 'bg-primary text-white'} p-2`}
                        style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
                      >
                        <ReactMarkdown
                          components={{
                            // Render paragraphs as inline elements instead of block-level paragraphs.
                            p: ({ node, children, ...props }) => <span {...props}>{children}</span>,
                          }}
                        >
                          {msg.content}
                        </ReactMarkdown>

                      </div>
                    </div>
                  </div>
                ))}
                {isLoading && (
                  <div className="d-flex align-items-center mt-2">
                    <span>Searching citation rules and generating a response...</span>
                    <div className="spinner-border spinner-border-sm text-secondary ml-2" role="status"></div>
                  </div>
                )}
              </div>
              <div className="d-flex w-100" style={{ flexShrink: 0 }}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ask more questions or provide more details..."
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={(e) => { if (e.key === 'Enter') handleSend(); }}
                  ref={chatInputRef}
                  aria-label="Chat Input"
                />
                <button className="btn btn-primary ml-2" onClick={handleSend}>
                  Send
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="card shadow-sm border-0 w-100">
            <div className="card-body p-4">
              <div className="d-flex">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter source information..."
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={(e) => { if (e.key === 'Enter') handleSend(); }}
                  ref={initialInputRef}
                  aria-label="Source Information Input"
                />
                <button className="btn btn-primary ml-2" onClick={handleSend}>
                  Start
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Check Brief Citations Button (placed beneath the chat bar) */}
        <div className="text-center mt-2 w-100">
          <button className="btn btn-outline-info" onClick={handleCheckBriefCitations}>
            Check Brief Citations
          </button>
        </div>

        <Analytics />
      </div>

      {/* Hidden file input for DOCX upload */}
      <input
        type="file"
        accept=".docx"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleBriefUpload}
      />

      {/* Fixed Feedback Button */}
      <a
        href="https://jamesfbaker.substack.com/p/how-i-made-bluey-bot"
        target="_blank"
        rel="noopener noreferrer"
        className="feedback-button btn btn-primary"
        aria-label="Blog"
      >
        Blog
      </a>

      {/* Credit Purchase Modal */}
      <CreditPurchaseModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onSelectPlan={handleSelectPlan}
      />
    </>
  );
}

export default App;
